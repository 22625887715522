import { User } from "oidc-client-ts";

import { userManager } from "./user-manager";

const { REACT_APP_AUTH_URL: AUTH_URL = "" } = process.env;

const initiateSignIn = async (): Promise<void> => {
  try {
    await userManager.signinRedirect();
  } catch (error) {
    console.error("Error initiating sign-in:", error);
  }
};

const handleCallback = async (): Promise<void> => {
  const urlParameters = new URLSearchParams(window.location.search);
  const code = urlParameters.get("code");

  if (code) {
    try {
      const response = await fetch(AUTH_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ code }),
      });
      const responseData = await response.json();

      const userToStore = new User({
        id_token: responseData.id_token,
        access_token: responseData.access_token,
        token_type: responseData.token_type,
        scope: responseData.scope,
        profile: responseData.profile,
        expires_at: responseData.expires_at,
      });

      await userManager.storeUser(userToStore);
      renderApp();
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
};

const renderApp = () => {
  // eslint-disable-next-line no-restricted-globals
  history.replaceState(undefined, "", "/");
  void import("./init");
};

(async () => {
  const user = await userManager.getUser();

  if (!user && window.location.pathname !== "/auth/callback") {
    await initiateSignIn();
  } else if (window.location.pathname === "/auth/callback") {
    await handleCallback();
  } else {
    renderApp();
  }
})();
