import { UserManager } from "oidc-client-ts";

const { REACT_APP_GOOGLE_CLIENT_ID: CLIENT_ID = "" } = process.env;

const { origin } = globalThis.location;

const userManager = new UserManager({
  authority: "https://accounts.google.com",
  client_id: CLIENT_ID,
  redirect_uri: `${origin}/auth/callback`,
  response_type: "code",
  disablePKCE: true,
  scope: "openid profile email",
  automaticSilentRenew: true,
});

export { userManager };
